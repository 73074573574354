import React, { Component } from 'react'
import { connect } from 'react-redux'
import { graphql } from 'gatsby'
import SEO from '@/components/SEO/SEO'
import FeedHeader from '@/components/FeedHeader/FeedHeader'
import PostThumb from '@/components/PostThumb/PostThumb'
import ContentGrid from '@/components/ContentGrid/ContentGrid'
import Pagination from '@/components/Pagination/Pagination'
import { prepItem, createPaginationLinks, getSeoImage } from '@/utils'
import { setupPage } from '@/utils/common'

class EpisodeIndex extends Component {
	constructor(props) {
		super(props)

		setupPage(props)
	}

	render() {
		const {
			pageContext: {
				group,
				index,
				first,
				last,
				pageCount,
				pathPrefix,
				additionalContext: { title, subtitle, seo }
			},
			location
		} = this.props

		const seoImage = getSeoImage(seo)

		const entries = prepItem(group)

		const { pages, previousUrl, nextUrl } = createPaginationLinks({
			index,
			pageCount,
			pathPrefix
		})

		return (
			<div className="pb-2">
				<SEO
					title={seo.title || title}
					description={seo.description || subtitle}
					socialTitle={seo.title || title}
					socialDesc={seo.description || subtitle}
					image={seoImage}
					url={location.href}
				/>
				<FeedHeader title={title}>
					{subtitle && <p className="text-xl-fluid">{subtitle}</p>}
				</FeedHeader>
				<ContentGrid
					content={entries}
					render={item => <PostThumb {...item} />}
				/>

				{pages.length > 1 && (
					<div className="wrapper">
						<Pagination
							prev={`${pathPrefix}/${previousUrl}`}
							next={`${pathPrefix}/${nextUrl}`}
							first={first}
							last={last}
							pages={pages}
						/>
					</div>
				)}
			</div>
		)
	}
}

const mapState = state => ({
	...state.player
})

const mapDispatch = ({ player: { setPageType, setAudioBarVisibility } }) => ({
	setPageType,
	setAudioBarVisibility
})

export default connect(
	mapState,
	mapDispatch
)(EpisodeIndex)

// export const pageQuery = graphql`
// 	query EpisodeListing {
// 		craft {
// 			category(categ: $id) {
// 				title
// 				... on Craft_EpisodesCategory {
// 					subtitle
// 					seo {
// 						title
// 						description
// 						social {
// 							twitter {
// 								title
// 								image {
// 									url
// 								}
// 								description
// 							}
// 							facebook {
// 								title
// 								image {
// 									url
// 								}
// 								description
// 							}
// 						}
// 					}
// 				}
//     	}
//   	}
// 	}
// `

